import HeadBar from './head.vue';
import FootBar from './footer.vue';
import CommonPage from './common.vue'
import Wrapper from './wrapper.vue'
import Vue from 'vue';
import Ad from './AdComponent.vue'

Vue.component('HeadBar',HeadBar)
Vue.component('FootBar',FootBar)
Vue.component('CommonPage',CommonPage)
Vue.component('Wrapper',Wrapper)
Vue.component('Ad',Ad)