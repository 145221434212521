import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			list: [],
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
				ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1354755405', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '9490425983', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3136900503', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1839296550', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7728592067', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2725002812', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
		showDebug: false,
		firebaseConfig: {
			apiKey: "AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo",
			authDomain: "webs-58a8d.firebaseapp.com",
			projectId: "webs-58a8d",
			storageBucket: "webs-58a8d.appspot.com",
			messagingSenderId: "730684174767",
			appId: "1:730684174767:web:3b21138190c8db380c3f5a",
			measurementId: "G-XJLTH7VQH0"
		}
	},
		mutations: {
		setHost(state, host) {
			state.host = host
		},
		setTitle(state, title) {
			state.title = title
		},
		setDomain(state, domainConfig) {
			state.domainConfig = domainConfig
		},
		setDeviceType(state, deviceType) {
			state.deviceType = deviceType
		},
		setDebug(state, showDebug) {
			state.showDebug = showDebug
		},
	},
		getters: {
		showDebug: (state) => state.showDebug,
		deviceType: (state) => state.deviceType,
		host: (state) => state.host,
		adsensConfig: (state) => state.adsensConfig,
	},
	})
}
