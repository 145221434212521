<template>
	<div id="app">
		<keep-alive>
			<router-view :key="$route.path" />
		</keep-alive>
	</div>
</template>

<script>
import '@/css/main.scss'

export default {
	name: 'App',
	beforeMount() {
		const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		if (flag) {
			this.$store.state.deviceType = 'mobile'
		} else {
			this.$store.state.deviceType = 'desktop'
		}
		console.log(this.$store.state.deviceType)
	},
	created() {
		let debug = this.$global.GetQueryString('db')
		if (debug) {
			this.$store.commit('setDebug', true)
		}
	},
	mounted() {
		setTimeout(() => {
			;(function (c, l, a, r, i, t, y) {
        // console.log(23);
				c[a] =
					c[a] ||
					function () {
						;(c[a].q = c[a].q || []).push(arguments)
					}
				t = l.createElement(r)
				t.async = 1
				t.src = 'https://www.clarity.ms/tag/' + i
				y = l.getElementsByTagName(r)[0]
				y.parentNode.insertBefore(t, y)
			})(window, document, 'clarity', 'script', 'p2d2evkq25')
		}, 3000)
	},
}
</script>
