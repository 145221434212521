<template>
	<div
		class="wrapperComponent"
		:style="{ backgroundColor: `${color}` }">
		<div
			class="in"
			:style="{ backgroundColor: `${color}` }">
			<slot />
		</div>
	</div>
</template>
  
  <script>
//   传入两边的颜色
export default {
	props: ['color'],
}
</script>
  <style lang="scss" scoped>
@media screen and (orientation: landscape) {
	.wrapperComponent {
		.in {
			max-width: 62.5vw;
			margin: auto;
		}
	}
}
</style>